<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-ofertas-export"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Reporte Ofertas</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="id_planeacion" class="form-group col-md-2"
                      >PLANEACION</label
                    >
                    <v-select
                      :class="[
                        $v.form.id_planeacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="planeacion"
                      placeholder="Planeaciones"
                      label="descripcion"
                      id="id_planeacion"
                      class="form-control form-control-sm p-0"
                      :options="listasForms.planeaciones"
                      :filterable="true"
                      @input="getSelectPlaneaciones()"
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                v-show="!$v.form.$invalid"
                @click="generarListadoExcel()"
                v-if="
                  $store.getters.can('hidrocarburos.reportes.ofertasExport')
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "ReporteOfertasExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      planeacion: {},
      form: {
        id_planeacion: null,
      },
      listasForms: {
        planeaciones: [],
      },
    };
  },
  validations() {
    return {
      form: {
        id_planeacion: {
          required,
        },
      },
    };
  },
  methods: {
    getSelectPlaneaciones() {
      this.form.planeacion = {};
      this.form.id_planeacion = null;
      if (this.planeacion) {
        this.form.planeacion = this.planeacion;
        this.form.id_planeacion = this.planeacion.id;
      }
    },
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/ofertas/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
