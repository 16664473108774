<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Reportes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Consultas</li>
                  <li class="breadcrumb-item active">Reportes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card-body p-5">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="input-group">
                    <h1 class="m-0 text-dark text-center">
                      Tablero de Reportes
                    </h1>
                    <div class="input-group-append"></div>
                  </div>
                  <div class="btn-group float"></div>
                </div>
              </div>
              <div class="card-body">
                <div class="row clearfix">
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-guia-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fa fa-list fa-3x"
                              style="color: green"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Exportar Guía</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can('hidrocarburos.reportes.exportGeneral')
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-general-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-file-alt fa-3x"
                              style="color: #31c177"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte General</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can('hidrocarburos.reportes.ofertasExport')
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-ofertas-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fab fa-buffer fa-3x"
                              style="color: #f7f413"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Lista Ofertas</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.programacionExport'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-programaciones-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-clipboard-list fa-3x"
                              style="color: #1013cc"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Lista Programaciones</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.solicitudExport'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-solicitudes-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-business-time fa-3x"
                              style="color: #7917a3"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Solicitudes Diarias</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.nominacionExport'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-nominacion"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-users fa-3x"
                              style="color: blue"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Nominaciones</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.postulacionExport'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-postulacion-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-money-check fa-3x"
                              style="color: #e7c30f"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Informe Postulaciones</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.exportConsolidado'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-consolidado-transporte-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-bus fa-3x"
                              style="color: #0ef3f3"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Consolidado Transporte</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can('hidrocarburos.reportes.enturneExport')
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-enturne-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-sign-in-alt fa-3x"
                              style="color: #1c0834"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Enturnes</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can('hidrocarburos.reportes.ticketsExport')
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-ticket-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-ticket-alt fa-3x"
                              style="color: #ee050c"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Exportar Ticket</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.exportInspecciones'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-inspecciones-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-file-signature fa-3x"
                              style="color: #209009"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Inspecciones</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can('hidrocarburos.reportes.adicionales')
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-adicional-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-plus-circle fa-3x"
                              style="color: #7e14e1"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Adicionales</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can('hidrocarburos.reportes.informeGuias')
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-guia-completa"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fa fa-list fa-3x"
                              style="color: #10b533"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Informe De Guías</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.regaliasExport'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-regalia-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-handshake fa-3x"
                              style="color: #1663da"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Regalias y Terceros</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.reporteCumplimiento'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-cumplimiento"
                      >
                        <p align="center">
                          <span class="icon">
                            <i
                              class="fas fa-percent fa-3x"
                              style="color: rgb(13, 244, 179)"
                            ></i>
                          </span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Cumplimiento</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.reporteCumplimiento'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-consolidado-ticket"
                      >
                        <p align="center">
                          <span class="icon">
                            <i
                              class="fas fa-truck-moving fa-3x"
                              style="color: #ec850a"
                            ></i>
                          </span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Consolidado Transporte Ticket</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can('hidrocarburos.reportes.reporteCargue')
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-Diario-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-file-alt fa-3x"
                              style="color: green"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Diario De Cargue</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.reporteDescargue'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-DiarioD-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-file-alt fa-3x"
                              style="color: #1c0834"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Diario De Descargue</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.exportReporteEcopetrol'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-ecopetrol-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-file-alt fa-3x"
                              style="color: #70ad48"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Ecopetrol</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.reportes.exportReporteEcopetrol'
                      )
                    "
                  >
                    <div
                      class="card card-widget widget-user"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.reportes.exportReporteEscaner'
                        )
                      "
                    >
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-escaner-export"
                      >
                        <p align="center">
                          <span class="icon">
                            <i
                              class="fas fa-file-archive fa-3x"
                              style="color: #992015"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte Escáner Guías</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <!-- <div class="col-md-3 column">
                    <div class="feature-box">
                      <span class="feature-icon"
                        ><i class="fa fa-pencil-square-o"></i
                      ></span>
                      <h4>Easy to use/customizable</h4>
                      <span>Well coded Easy to use in website</span>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="feature-box">
                      <span class="feature-icon"
                        ><i class="fa fa-eye"></i
                      ></span>
                      <h4>Coming Soon</h4>
                      <span>Theme Option Coming Soon</span>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <ReporteOfertasExport ref="ReporteOfertasExport" />
    <ReporteNominacionExport ref="ReporteNominacionExport" />
    <ReporteGuiaExport ref="ReporteGuiaExport" />
    <ReporteProgramacionesExport ref="ReporteProgramacionesExport" />
    <ReporteSolicitudesExport ref="ReporteSolicitudesExport" />
    <ReporteGeneralExport ref="ReporteGeneralExport" />
    <PostulacionExport ref="PostulacionExport" />
    <ConsolidadoTransporteExport ref="ConsolidadoTransporteExport" />
    <ReporteEnturneExport ref="ReporteEnturneExport" />
    <ReporteTicketExport ref="ReporteTicketExport" />
    <ReporteInspeccionesExport ref="ReporteInspeccionesExport" />
    <ReporteAdicionalesExport ref="ReporteAdicionalesExport" />
    <ReporteGuiaCompleta ref="ReporteGuiaCompleta" />
    <ReporteRegaliaExport ref="ReporteRegaliaExport" />
    <reporte-cumplimiento-export ref="ReporteCumplimientoExport" />
    <ReporteDiarioDeCargueExport ref="ReporteDiarioDeCargueExport" />
    <ReporteDiarioDeDescargueExport ref="ReporteDiarioDeDescargueExport" />
    <ConsolidadoTransporteTicketExport
      ref="ConsolidadoTransporteTicketExport"
    />
    <ReporteEcopetrolExport ref="ReporteEcopetrolExport" />
    <ReporteEscanerGuiasExport ref="ReporteEscanerGuiasExport" />
  </div>
</template>

<script>
import ReporteGuiaExport from "./ReporteGuiaExport";
import ReporteNominacionExport from "./ReporteNominacionExport";
import ReporteOfertasExport from "./ReporteOfertasExport";
import ReporteProgramacionesExport from "./ReporteProgramacionesExport";
import ReporteSolicitudesExport from "./ReporteSolicitudesExport";
import ReporteGeneralExport from "./ReporteGeneralExport";
import PostulacionExport from "./PostulacionExport";
import ConsolidadoTransporteExport from "./ConsolidadoTransporteExport";
import ReporteEnturneExport from "./ReporteEnturneExport";
import ReporteTicketExport from "./ReporteTicketExport";
import ReporteInspeccionesExport from "./ReporteInspeccionesExport";
import ReporteAdicionalesExport from "./ReporteAdicionalesExport";
import ReporteGuiaCompleta from "./ReporteGuiaCompleta";
import ReporteRegaliaExport from "./ReporteRegaliaExport";
import ReporteDiarioDeCargueExport from "./ReporteDiarioDeCargueExport";
import ReporteDiarioDeDescargueExport from "./ReporteDiarioDeDescargueExport";
import axios from "axios";
import ReporteCumplimientoExport from "./ReporteCumplimientoExport.vue";
import ConsolidadoTransporteTicketExport from "./ConsolidadoTransporteTicketExport";
import ReporteEcopetrolExport from "./ReporteEcopetrolExport";
import ReporteEscanerGuiasExport from "./ReporteEscanerGuiasExport";
export default {
  name: "ReporteIndex",
  data() {
    return {
      cargando: false,
      sitio_origen: {},
      sitio_destino: {},
      listasForms: {
        productos_liquidos: [],
        sitiosOrigenes: [],
        sitiosDestinos: [],
        empresas: [],
        listaConsultas: [],
      },
    };
  },
  components: {
    ReporteGuiaExport,
    ReporteNominacionExport,
    ReporteOfertasExport,
    ReporteProgramacionesExport,
    ReporteSolicitudesExport,
    ReporteGeneralExport,
    PostulacionExport,
    ConsolidadoTransporteExport,
    ReporteEnturneExport,
    ReporteTicketExport,
    ReporteInspeccionesExport,
    ReporteAdicionalesExport,
    ReporteGuiaCompleta,
    ReporteRegaliaExport,
    ReporteCumplimientoExport,
    ConsolidadoTransporteTicketExport,
    ReporteDiarioDeCargueExport,
    ReporteDiarioDeDescargueExport,
    ReporteEcopetrolExport,
    ReporteEscanerGuiasExport,
  },
  methods: {
    async getProductoLiquido() {
      await axios.get("api/hidrocarburos/Productos/lista").then((response) => {
        this.$refs.ReporteGeneralExport.listasForms.productos_liquidos =
          response.data;
        this.$refs.ReporteGuiaExport.listasForms.productos_liquidos =
          response.data;
        this.$refs.ConsolidadoTransporteExport.listasForms.productos_liquidos =
          response.data;
        this.$refs.ReporteEnturneExport.listasForms.productos_liquidos =
          response.data;
        this.$refs.ReporteTicketExport.listasForms.productos_liquidos =
          response.data;
        this.$refs.ReporteGuiaCompleta.listasForms.productos_liquidos =
          response.data;
        this.$refs.ReporteRegaliaExport.listasForms.productos_liquidos =
          response.data;
        this.$refs.ReporteDiarioDeDescargueExport.listasForms.productos_liquidos =
          response.data;
        this.$refs.ReporteEcopetrolExport.listasForms.productos_liquidos =
          response.data;
      });
    },
    async getSitios() {
      await axios.get("api/admin/sitios/lista").then((response) => {
        this.$refs.ReporteGeneralExport.listasForms.sitiosOrigenes =
          response.data;
        this.$refs.ReporteGuiaExport.listasForms.sitiosOrigenes = response.data;
        this.$refs.ReporteSolicitudesExport.listasForms.sitios = response.data;
        this.$refs.ReporteGeneralExport.listasForms.sitiosDestinos =
          response.data;
        this.$refs.ReporteGuiaExport.listasForms.sitiosDestinos = response.data;
        this.$refs.PostulacionExport.listasForms.sitios = response.data;
        this.$refs.ConsolidadoTransporteExport.listasForms.sitiosOrigenes =
          response.data;
        this.$refs.ConsolidadoTransporteExport.listasForms.sitiosDestinos =
          response.data;
        this.$refs.ReporteEnturneExport.listasForms.sitiosOrigenes =
          response.data;
        this.$refs.ReporteEnturneExport.listasForms.sitiosDestinos =
          response.data;
        this.$refs.ReporteTicketExport.listasForms.sitiosOrigenes =
          response.data;
        this.$refs.ReporteTicketExport.listasForms.sitiosDestinos =
          response.data;
        this.$refs.ReporteAdicionalesExport.listasForms.sitios = response.data;
        this.$refs.ReporteGuiaCompleta.listasForms.sitiosOrigenes =
          response.data;
        this.$refs.ReporteGuiaCompleta.listasForms.sitiosDestinos =
          response.data;
        this.$refs.ReporteRegaliaExport.listasForms.sitiosOrigenes =
          response.data;
        this.$refs.ReporteRegaliaExport.listasForms.sitiosDestinos =
          response.data;
        this.$refs.ReporteDiarioDeCargueExport.listasForms.sitiosOrigenes =
          response.data;
        this.$refs.ReporteDiarioDeCargueExport.listasForms.sitiosDestinos =
          response.data;
        this.$refs.ReporteDiarioDeDescargueExport.listasForms.sitiosOrigenes =
          response.data;
        this.$refs.ReporteDiarioDeDescargueExport.listasForms.sitiosDestinos =
          response.data;
        this.$refs.ReporteEcopetrolExport.listasForms.sitiosDestinos =
          response.data;
        this.$refs.ReporteEcopetrolExport.listasForms.sitiosOrigenes =
          response.data;
        this.$refs.ReporteEscanerGuiasExport.listasForms.sitiosOrigenes =
          response.data;
      });
    },
    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.$refs.ReporteGeneralExport.listasForms.empresas = response.data;
        this.$refs.ConsolidadoTransporteExport.listasForms.empresas =
          response.data;
        this.$refs.ReporteGuiaExport.listasForms.empresas = response.data;
        this.$refs.PostulacionExport.listasForms.empresas = response.data;
        this.$refs.ReporteEnturneExport.listasForms.empresas = response.data;
        this.$refs.ReporteTicketExport.listasForms.empresas = response.data;
        this.$refs.ReporteInspeccionesExport.listasForms.empresas =
          response.data;
        this.$refs.ReporteAdicionalesExport.listasForms.empresas =
          response.data;
        this.$refs.ReporteGuiaCompleta.listasForms.empresas = response.data;
        this.$refs.ReporteRegaliaExport.listasForms.empresas = response.data;
        this.$refs.ReporteDiarioDeCargueExport.listasForms.empresas =
          response.data;
        this.$refs.ReporteDiarioDeDescargueExport.listasForms.empresas =
          response.data;
        this.$refs.ReporteEcopetrolExport.listasForms.empresas = response.data;
        this.$refs.ReporteEscanerGuiasExport.listasForms.empresas =
          response.data;
      });
    },
    async getTipoOperacion() {
      axios.get("/api/lista/57").then((response) => {
        this.$refs.ReporteGuiaExport.listasForms.tipo_operacion = response.data;
        this.$refs.ReporteGuiaCompleta.listasForms.tipo_operacion =
          response.data;
      });
    },
    async getPlaneaciones() {
      await axios.get("/api/hidrocarburos/ofertas/lista").then((response) => {
        this.$refs.ReporteOfertasExport.listasForms.planeaciones =
          response.data;
      });
    },
    async getNominaciones() {
      this.cargando = true;
      axios.get("/api/hidrocarburos/nominacion/list").then((response) => {
        this.$refs.ReporteSolicitudesExport.listasForms.nominaciones =
          response.data;
      });
    },
    async getTiposCombustibles() {
      axios.get("/api/lista/24").then((response) => {
        this.$refs.ReporteGuiaExport.listasForms.tiposCombustible =
          response.data;
        this.$refs.ReporteGeneralExport.listasForms.tiposCombustible =
          response.data;
        this.$refs.PostulacionExport.listasForms.tiposCombustible =
          response.data;
        this.$refs.ReporteGuiaCompleta.listasForms.tiposCombustible =
          response.data;
        this.$refs.ReporteInspeccionesExport.listasForms.tiposCombustible =
          response.data;
        this.$refs.ReporteEcopetrolExport.listasForms.tiposCombustible =
          response.data;
      });
    },
    async getNominacionesC() {
      await axios
        .get("/api/hidrocarburos/nominacion/lista")
        .then((response) => {
          this.$refs.ReporteNominacionExport.listasForms.nominaciones =
            response.data;
          this.$refs.ReporteCumplimientoExport.listasForms.nominaciones =
            response.data;
        });
    },
    async getTipoRuta() {
      axios.get("/api/lista/48").then((response) => {
        this.$refs.PostulacionExport.listasForms.tipo_ruta = response.data;
        this.$refs.ReporteAdicionalesExport.listasForms.tipo_rutas =
          response.data;
      });
    },
    getTipoVehiculos() {
      axios.get("api/admin/tiposVehiculos/lista").then((response) => {
        this.$refs.ReporteDiarioDeCargueExport.listasForms.tiposVehiculos =
          response.data;
        this.$refs.ReporteDiarioDeDescargueExport.listasForms.tiposVehiculos =
          response.data;
        this.$refs.ReporteEcopetrolExport.listasForms.tiposVehiculos =
          response.data;
      });
    },
    getEstados() {
      axios.get("/api/lista/63").then((response) => {
        this.$refs.ReporteAdicionalesExport.listasForms.estados = response.data;
      });
    },
    getVehiculos() {
      axios.get("/api/admin/vehiculos/lista").then((response) => {
        this.$refs.ReporteDiarioDeCargueExport.listasForms.placas =
          response.data;
        this.$refs.ReporteDiarioDeDescargueExport.listasForms.placas =
          response.data;
        this.$refs.ReporteEcopetrolExport.listasForms.placas = response.data;
      });
    },
    getRemolques() {
      axios.get("/api/admin/remolques/lista").then((response) => {
        this.$refs.ReporteDiarioDeCargueExport.listasForms.remolques =
          response.data;
        this.$refs.ReporteDiarioDeDescargueExport.listasForms.remolques =
          response.data;
      });
    },
    getGuias() {
      axios.get("/api/admin/guias/lista").then((response) => {
        this.$refs.ReporteDiarioDeCargueExport.listasForms.n_guia =
          response.data;
        this.$refs.ReporteDiarioDeDescargueExport.listasForms.n_guia =
          response.data;
      });
    },
    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.$refs.ReporteDiarioDeCargueExport.listasForms.bloques =
          response.data;
        this.$refs.ReporteDiarioDeDescargueExport.listasForms.bloques =
          response.data;
      });
    },
    getConductor() {
      axios.get("api/admin/conductores/lista").then((response) => {
        this.$refs.ReporteDiarioDeCargueExport.listasForms.conductores =
          response.data;
        this.$refs.ReporteDiarioDeDescargueExport.listasForms.conductores =
          response.data;
        this.$refs.ReporteEcopetrolExport.listasForms.conductores =
          response.data;
      });
    },

    getListaConsulta() {
      axios.get("/api/lista/47").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].numeracion != 2) {
            this.$refs.ReporteEscanerGuiasExport.listasForms.listaConsultas.push(
              response.data[i]
            );
          }
        }
      });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getSitios();
    await this.getProductoLiquido();
    await this.getEmpresas();
    await this.getTipoOperacion();
    await this.getPlaneaciones();
    await this.getTiposCombustibles();
    await this.getNominaciones();
    await this.getNominacionesC();
    await this.getTipoRuta();
    await this.getEstados();
    await this.getVehiculos();
    await this.getRemolques();
    await this.getTipoVehiculos();
    await this.getBloques();
    await this.getConductor();
    await this.getListaConsulta();
    this.cargando = false;
  },
};
</script>
