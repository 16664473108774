var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Hidrocarburos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Consultas")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Reportes")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card-body p-5"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row clearfix"},[_vm._m(2),(
                    _vm.$store.getters.can('hidrocarburos.reportes.exportGeneral')
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(3)]):_vm._e(),(
                    _vm.$store.getters.can('hidrocarburos.reportes.ofertasExport')
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(4)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.programacionExport'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(5)]):_vm._e()]),_c('div',{staticClass:"row clearfix"},[(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.solicitudExport'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(6)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.nominacionExport'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(7)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.postulacionExport'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(8)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.exportConsolidado'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(9)]):_vm._e()]),_c('div',{staticClass:"row clearfix"},[(
                    _vm.$store.getters.can('hidrocarburos.reportes.enturneExport')
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(10)]):_vm._e(),(
                    _vm.$store.getters.can('hidrocarburos.reportes.ticketsExport')
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(11)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.exportInspecciones'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(12)]):_vm._e(),(
                    _vm.$store.getters.can('hidrocarburos.reportes.adicionales')
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(13)]):_vm._e()]),_c('div',{staticClass:"row clearfix"},[(
                    _vm.$store.getters.can('hidrocarburos.reportes.informeGuias')
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(14)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.ventanasOperaciones.regaliasExport'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(15)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.reporteCumplimiento'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(16)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.reporteCumplimiento'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(17)]):_vm._e(),(
                    _vm.$store.getters.can('hidrocarburos.reportes.reporteCargue')
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(18)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.reporteDescargue'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(19)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.exportReporteEcopetrol'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(20)]):_vm._e(),(
                    _vm.$store.getters.can(
                      'hidrocarburos.reportes.exportReporteEcopetrol'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[(
                      _vm.$store.getters.can(
                        'hidrocarburos.reportes.exportReporteEscaner'
                      )
                    )?_c('div',{staticClass:"card card-widget widget-user"},[_vm._m(21)]):_vm._e()]):_vm._e()])])])])])])]),_c('ReporteOfertasExport',{ref:"ReporteOfertasExport"}),_c('ReporteNominacionExport',{ref:"ReporteNominacionExport"}),_c('ReporteGuiaExport',{ref:"ReporteGuiaExport"}),_c('ReporteProgramacionesExport',{ref:"ReporteProgramacionesExport"}),_c('ReporteSolicitudesExport',{ref:"ReporteSolicitudesExport"}),_c('ReporteGeneralExport',{ref:"ReporteGeneralExport"}),_c('PostulacionExport',{ref:"PostulacionExport"}),_c('ConsolidadoTransporteExport',{ref:"ConsolidadoTransporteExport"}),_c('ReporteEnturneExport',{ref:"ReporteEnturneExport"}),_c('ReporteTicketExport',{ref:"ReporteTicketExport"}),_c('ReporteInspeccionesExport',{ref:"ReporteInspeccionesExport"}),_c('ReporteAdicionalesExport',{ref:"ReporteAdicionalesExport"}),_c('ReporteGuiaCompleta',{ref:"ReporteGuiaCompleta"}),_c('ReporteRegaliaExport',{ref:"ReporteRegaliaExport"}),_c('reporte-cumplimiento-export',{ref:"ReporteCumplimientoExport"}),_c('ReporteDiarioDeCargueExport',{ref:"ReporteDiarioDeCargueExport"}),_c('ReporteDiarioDeDescargueExport',{ref:"ReporteDiarioDeDescargueExport"}),_c('ConsolidadoTransporteTicketExport',{ref:"ConsolidadoTransporteTicketExport"}),_c('ReporteEcopetrolExport',{ref:"ReporteEcopetrolExport"}),_c('ReporteEscanerGuiasExport',{ref:"ReporteEscanerGuiasExport"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Reportes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header pt-2 pb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-11"}),_c('div',{staticClass:"input-group"},[_c('h1',{staticClass:"m-0 text-dark text-center"},[_vm._v(" Tablero de Reportes ")]),_c('div',{staticClass:"input-group-append"})]),_c('div',{staticClass:"btn-group float"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 column"},[_c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-reporte-guia-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-list fa-3x",staticStyle:{"color":"green"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Exportar Guía")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-reporte-general-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-file-alt fa-3x",staticStyle:{"color":"#31c177"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte General")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-ofertas-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fab fa-buffer fa-3x",staticStyle:{"color":"#f7f413"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Lista Ofertas")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-programaciones-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-clipboard-list fa-3x",staticStyle:{"color":"#1013cc"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Lista Programaciones")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-solicitudes-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-business-time fa-3x",staticStyle:{"color":"#7917a3"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Solicitudes Diarias")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-reporte-nominacion"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-users fa-3x",staticStyle:{"color":"blue"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte Nominaciones")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-postulacion-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-money-check fa-3x",staticStyle:{"color":"#e7c30f"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Informe Postulaciones")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-consolidado-transporte-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-bus fa-3x",staticStyle:{"color":"#0ef3f3"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Consolidado Transporte")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-enturne-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-sign-in-alt fa-3x",staticStyle:{"color":"#1c0834"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte Enturnes")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-ticket-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-ticket-alt fa-3x",staticStyle:{"color":"#ee050c"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Exportar Ticket")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-inspecciones-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-file-signature fa-3x",staticStyle:{"color":"#209009"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte Inspecciones")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-adicional-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-plus-circle fa-3x",staticStyle:{"color":"#7e14e1"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte Adicionales")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-reporte-guia-completa"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-list fa-3x",staticStyle:{"color":"#10b533"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Informe De Guías")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-regalia-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-handshake fa-3x",staticStyle:{"color":"#1663da"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte Regalias y Terceros")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-reporte-cumplimiento"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-percent fa-3x",staticStyle:{"color":"rgb(13, 244, 179)"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte Cumplimiento")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-consolidado-ticket"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-truck-moving fa-3x",staticStyle:{"color":"#ec850a"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Consolidado Transporte Ticket")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-reporte-Diario-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-file-alt fa-3x",staticStyle:{"color":"green"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte Diario De Cargue")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-reporte-DiarioD-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-file-alt fa-3x",staticStyle:{"color":"#1c0834"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte Diario De Descargue")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-reporte-ecopetrol-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-file-alt fa-3x",staticStyle:{"color":"#70ad48"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte Ecopetrol")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-reporte-escaner-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-file-archive fa-3x",staticStyle:{"color":"#992015"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte Escáner Guías")])])])
}]

export { render, staticRenderFns }