<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-ticket-export"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Listado Reporte Ticket
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="form-group">
                    <label for="">Fecha Inicial Cargue</label>

                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.fecha_inicial"
                      :class="
                        $v.form.fecha_inicial.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validaFechas"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Fecha Final Cargue</label>

                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.fecha_final"
                      :class="
                        $v.form.fecha_final.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @input="validaFechas"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="n_ticket" class="col-md-5">N° TICKET</label>
                    <input
                      type="number"
                      id="n_ticket"
                      v-model="filtros.n_ticket"
                      class="col-md-7 form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="producto_liquido_id" class="col-md-5"
                      >TIPO PRODUCTO</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.producto_liquido_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="producto_liquido_id in listasForms.productos_liquidos"
                        :key="producto_liquido_id.id"
                        :value="producto_liquido_id.id"
                      >
                        {{ producto_liquido_id.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="cedula_conductor" class="col-md-5"
                      >CEDULA CONDUCTOR</label
                    >
                    <input
                      type="number"
                      id="cedula_conductor"
                      v-model="filtros.cedula_conductor"
                      class="col-md-7 form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="placa" class="col-md-5">PLACA VEHICULO</label>
                    <input
                      type="text"
                      id="placa"
                      v-model="filtros.placa"
                      class="col-md-7 form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="transportadora_id" class="col-md-5"
                      >Empresa Transportadora</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.transportadora_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="transportadora_id in listasForms.empresas"
                        :key="transportadora_id.id"
                        :value="transportadora_id.id"
                      >
                        {{ transportadora_id.razon_social }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="sitio_origen_id" class="col-md-5"
                      >LUGAR DE ORIGEN</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio_origen"
                      placeholder="Lugares de Origen"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.sitiosOrigenes"
                      :filterable="true"
                      @input="getSelectSitioOrigen()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="sitio_destino_id" class="col-md-5"
                      >LUGAR DE DESTINO</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio_destino"
                      placeholder="Lugares de Destino"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.sitiosDestinos"
                      :filterable="true"
                      @input="getSelectSitioDestino()"
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                v-show="!$v.form.$invalid"
                @click="generarListadoExcel()"
                v-if="
                  $store.getters.can('hidrocarburos.reportes.ticketsExport')
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "ReporteGuiaExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      guia: [],
      sitio_origen: {},
      sitio_destino: {},
      form: {
        fecha_inicial: "",
        hora_inicial: "00:00:00",
        fecha_final: "",
        hora_final: "23:59:00",
      },
      filtros: {
        n_ticket: null,
        producto_liquido_id: null,
        cedula_conductor: null,
        transportadora_id: null,
        sitio_origen_id: null,
        sitio_destino_id: null,
        placa: null,
      },
      listasForms: {
        productos_liquidos: [],
        sitiosOrigenes: [],
        sitiosDestinos: [],
        empresas: [],
      },
    };
  },
  validations: {
    form: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },
  methods: {
    validaFechas() {
      if (this.form.fecha_inicial) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.form.fecha_inicial);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.form.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        var fecha_menor = new Date(this.form.fecha_inicial);
        var fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        fecha_menor = new Date(this.form.fecha_inicial).getTime();
        fecha_mayor = new Date(this.form.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_final) {
        var hoy = new Date();
        const fecha_final = new Date(this.form.fecha_final);
        fecha_final.setDate(fecha_final.getDate());
        if (fecha_final >= hoy) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha final debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    getSelectSitioOrigen() {
      this.filtros.sitio_origen = {};
      this.filtros.sitio_origen_id = null;
      if (this.sitio_origen) {
        this.filtros.sitio_origen = this.sitio_origen;
        this.filtros.sitio_origen_id = this.sitio_origen.id;
      }
    },

    getSelectSitioDestino() {
      this.filtros.sitio_destino = {};
      this.filtros.sitio_destino_id = null;
      if (this.sitio_destino) {
        this.filtros.sitio_destino = this.sitio_destino;
        this.filtros.sitio_destino_id = this.sitio_destino.id;
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/detalleTickets/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
